import { Component } from "fq-foundation/Scripts/Component";

new Component().create("Modal", {
    element: ".f-modals .modal",
    permaCloseKey: window.location.hostname,
    init: function () {
        var self = this;
        $(self.element).each(function() {
            var modal = $(this);
            var settings = $(this).data('modal-settings');
            var modalEvent = settings.event;
            var delay = settings.delay;
            var closePermanently = settings["closePermanent"];
            
            $(this).on('openmodal', function (e) {
                e.preventDefault();
                $(this).addClass('is-active');
            });

            $(this).find(".modal-close").on('click', function () {
                if(closePermanently) {
                    var k = self.permaCloseKey; // + JSON.stringify(settings);
                    window.localStorage.setItem(k, "true")
                }
                $(this).closest(self.element).data('closed', true);
                $(this).closest(self.element).removeClass('is-active');
            });
            var k = self.permaCloseKey; // + JSON.stringify(settings);
            if(modalEvent) {
                switch(modalEvent.toLowerCase().trim()) {
                    case "load": 
                        setTimeout(function () {
                            var closedPermanently = window.localStorage.getItem(k)
                            if(!closedPermanently) {
                                $(modal).addClass('is-active');
                            }                        
                        }, delay * 1000);
                    break;
                    case "scroll":
                        if((window.scrollY / window.innerHeight) * 100 >= delay) {
                            var closedPermanently = window.localStorage.getItem(k)
                            if(!closedPermanently  && !$(modal).data('closed')) {
                                $(modal).addClass('is-active');
                            }
                        }
                        window.onscroll = function () {
                            if((window.scrollY / window.innerHeight) * 100 >= delay) {
                                var closedPermanently = window.localStorage.getItem(k)
                                if(!closedPermanently  && !$(modal).data('closed')) {
                                    $(modal).addClass('is-active');
                                }
                            }
                        }
                    break;
                    default:
                        $("[data-modal='" + settings['trigger-el'] + "']").on("click", function (e) {
                            e.preventDefault();
                            var closedPermanently = window.localStorage.getItem(k)
                            if(!closedPermanently) {
                                $(modal).addClass('is-active');
                            }
                        });
                    break;
                }
            }
        });
    }
});